
.FullPageContent {
    margin-top: 15px;
}

.FullPageContent p {
    margin-bottom: 1.5em;
}

.FullPageContent ul {
    margin: 0 0 1.5em 0.5em;
}

.FullPageContent li {
    list-style-type: square;
    list-style-position: outside;
    margin-left: 1em;
    margin-bottom: 0.5em;
}

.ContentTitle {
    font-size: 1.4rem;
    margin: 0 0 0.2em;
    font-family: 'Oswald', sans-serif;
    border-bottom: 6px solid #CCC;
    color: #000;
    background: #F7F7F7;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-transform: uppercase;
}

.entry-content {
    margin: 1.5em 0 0;
    padding: 0 15px;
    padding-bottom: 25px;
}

.FullPageContent .table {
    border: 1px solid #d9d9d9;
    border-left-width: 0;
    margin: 0 -0.083em 2.083em 0;
    text-align: left;
    width: 100%;
}

.FullPageContent .table th{
    border-left: 1px solid #d9d9d9;
    font-weight: bold;
    padding: 0.5em 1.25em;
}

.FullPageContent .table td {
    border: 1px solid #d9d9d9;
    border-width: 1px 0 0 1px;
    padding: 0.5em 1.25em;
}

.FullPageContent a {
    color: #CC0000;
    text-decoration: none;
}