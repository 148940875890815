.sponsers {
    display: flex;
    flex-direction: column;
}

.sponsor {
    margin-left: auto;
    margin-right: auto;
}

.smallSponsers {
    flex-direction: row;
    height: 100px;
}