.Navigation {
    background: #222;
    color: #FFF;
    float: left;
    width: 100%;
    height: auto;
    min-height: 40px;
    clear: both;
    position: relative;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    overflow: hidden;
}

.active {
    background-color: #CC0000;
}

/* Change the color of links on hover */
.Navigation a:hover {
    background-color: #CC0000;
}

.Navigation ul {
    margin: 0px 0 0 0;
    padding: 0px 0;
    padding-left: 12px;
    list-style-type: none;
    display:flex;
    justify-content: center;
}

.Navigation ul li {
    position: relative;
    display: inline;
    float: left;
    text-align: center;
}

.Navigation ul li a {
    color: #FFF;
    line-height: 20px;
    display: block;
    overflow: hidden;
    padding: 12px 20px 10px 20px;
    margin: 0;
    font-size: 1.4rem;
    font-size: 18px;
    text-decoration: none;
}

.Navigation .icon {
    float:right;
    margin-top: 4px;
    display: none;
}

.Navigation .FullNavigation {
    display: block;
}

.Navigation .miniDisplay {
    display: none;
}

@media (max-width: 1320px) {
    .Navigation .icon {
        display: block;
        position: absolute;
        margin-left: 90%;
    }
    .Navigation .miniDisplay {
        padding-top: 5px;
        font-size: 18px;
        position: absolute;
        display: block;
    }
    .Navigation .FullNavigation {
        display: none;
    }

    .responsive {
        display: contents !important
    }

    .responseLi {
        float: unset !important;
    }

    .Navigation .responsive ul {
      float: none;
      display: block;
      text-align: left;
    }
} 